@charset "UTF-8";
/* CSS Document */
/** Header
****************************************/
#topHeader {
  overflow: hidden;
  background-color: #fff;
  background-image: url(/common/pc/img/header_bg.png);
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: cover; }

#headerTxt {
  width: 616px;
  margin: 80px auto 70px;
  padding-right: 368px;
  text-align: center; }
  #headerTxt h1 {
    margin-bottom: 40px; }
  #headerTxt .txtCatch {
    margin-bottom: 40px;
    color: #6d4836;
    font-size: 36px;
    font-weight: normal;
    letter-spacing: 0.28em; }
  #headerTxt .txtCatch + p {
    line-height: 1.7;
    color: #6d4836;
    font-size: 23px; }
  #headerTxt p {
    color: #6d4836;
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 0.28em; }

#imgCatch {
  position: absolute;
  top: 26px;
  left: 50%;
  margin-left: 156px;
  background-image: url(/common/pc/img/header_img_pc.png);
  width: 290px;
  height: 610px; }
  #imgCatch img {
    display: none; }

/** App DL Button **/
#topHeader .appDL {
  overflow: hidden;
  padding: 34px 0;
  background-image: url(/common/pc/img/bg_2.jpg);
  background-repeat: repeat;
  text-align: center;
  color: #fff; }

#topHeader .appDL div {
  width: 616px;
  margin: 0 auto;
  padding-right: 368px; }

#topHeader .appDL p {
  font-size: 17px; }

#topHeader .appDL em {
  color: #ff0; }

#topHeader .appDL li {
  display: inline-block;
  margin: 10px 12px 0; }

#topHeader .appDL a:hover img {
  opacity: 0.8; }

/** Carousel
****************************************/
#areaStep {
  overflow: hidden;
  margin: 0 auto;
  padding: 80px 0;
  text-align: center; }

#carousel {
  position: relative;
  overflow: hidden;
  width: 984px;
  margin: 0 auto; }

.slick-slide {
  float: left; }

.sectionStep {
  overflow: hidden;
  margin: 0 auto;
  /*padding-left: 433px;*/
  height: 491px;
  text-align: left; }

.stepImg {
  float: left;
  margin: 0 24px 0 8px; }

.sectionStep h2 {
  overflow: hidden;
  margin-top: 110px;
  margin-bottom: 28px;
  color: #6d4836;
  font-size: 44px;
  font-weight: bold; }

.sectionStep h2:before {
  content: "";
  display: block;
  width: 210px;
  height: 66px;
  margin-bottom: 19px;
  background: url(/common/pc/img/step_img_step.png) no-repeat; }

.sectionStep p {
  margin-bottom: 1em;
  font-size: 18px; }

.sectionStep p img {
  margin-top: 5px; }

#step2 h2:before {
  background-position: 0 -66px; }

#step3 h2:before {
  background-position: 0 -132px; }

.slick-prev, .slick-next {
  position: absolute;
  top: 40%;
  left: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #6d4836;
  border-radius: 50px;
  background: #fff;
  text-align: center;
  text-indent: 2px;
  color: #6d4836;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer; }

.slick-next {
  left: inherit;
  right: 0;
  text-indent: 4px; }

.slick-disabled {
  visibility: hidden; }

.slick-dots {
  display: block;
  margin: 34px auto 0; }

.slick-dots li {
  display: inline-block;
  margin: 0 9px; }

.slick-dots li:hover {
  opacity: 0.7; }

.slick-dots li button {
  width: 44px;
  height: 44px;
  line-height: 35px;
  padding: 0;
  border: 2px solid #6d4836;
  border-radius: 50px;
  background: #fff;
  text-align: center;
  color: #6d4836;
  font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Osaka,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
  font-size: 22px;
  cursor: pointer; }

.slick-dots li.slick-active button {
  background: #6d4836;
  color: #fff; }

/** Campaign
****************************************/
#areaCampaign {
  padding-top: 34px;
  background-image: url(/common/pc/img/bg_3.jpg);
  background-repeat: repeat;
  text-align: center; }

/** Service
****************************************/
#areaService {
  padding: 100px 0;
  text-align: center; }

#areaService h2 {
  line-height: 1.6;
  margin-bottom: 54px;
  color: #6d4836;
  font-size: 34px;
  font-weight: normal;
  letter-spacing: 0.12em; }

#areaService section {
  display: inline-block;
  width: 252px;
  margin: 0 40px;
  text-align: center; }

#areaService section h3 {
  margin: 16px auto 12px;
  font-size: 18px; }

#areaService section p {
  text-align: left; }

/****************************************
Other Device(SP/Tablet)
****************************************/
@media screen and (max-width: 984px) {
  #headerTxt {
    width: 510px;
    margin: 80px auto 42px;
    padding-right: 220px; }
  #headerTxt h1 img {
    width: 450px; }
  #headerTxt .txtCatch {
    margin-bottom: 20px;
    font-size: 28px;
    letter-spacing: 0.1em; }
  #headerTxt .txtCatch + p {
    font-size: 18px; }
  #imgCatch {
    margin-left: 150px;
    background-image: url(/common/pc/img/header_img_pc.png);
    background-size: 200px 421px;
    width: 200px;
    height: 421px; }
    #imgCatch img {
      display: none; }
  #topHeader .appDL {
    padding: 36px 0; }
  #topHeader .appDL div {
    width: 410px;
    padding-right: 328px; }
  #topHeader .appDL p {
    font-size: 16px; }
  #topHeader .appDL li {
    width: 45%;
    margin: 10px 8px 0; }
  #topHeader .appDL img {
    width: 100%; }
  /** Carousel
	****************************************/
  #areaStep {
    overflow: hidden;
    margin: 0 auto;
    padding: 40px 0;
    text-align: center; }
  #carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto; }
  .slick-slide {
    float: left; }
  .sectionStep {
    overflow: hidden;
    height: inherit;
    margin: 0 auto;
    background-size: auto 190px !important;
    text-align: left; }
  .stepImg {
    width: 38%;
    margin: 0 8px 0 0; }
  .sectionStep h2 {
    margin-top: 55px;
    margin-bottom: 10px;
    color: #6d4836;
    font-size: 36px;
    font-weight: normal; }
  .sectionStep p {
    margin-bottom: 1em;
    padding-right: 8px;
    font-size: 16px; }
  .sectionStep p + p {
    display: none; }
  .slick-prev, .slick-next {
    display: none !important; }
  /** Campaign
	****************************************/
  #areaCampaign {
    padding-top: 20px; }
  #areaCampaign img {
    width: 94%; }
  /** Service
	****************************************/
  #areaService {
    padding: 40px 0;
    text-align: center; }
  #areaService h2 {
    line-height: 1.4;
    margin-bottom: 25px;
    color: #6d4836;
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 0.12em; }
  #areaService section {
    display: inline-block;
    width: 90%;
    margin: 0 0 25px;
    text-align: left; }
  #areaService section img {
    float: left;
    width: 30%;
    margin: 0 4% 0 0; }
  #areaService section h3 {
    margin: 20px auto 12px;
    font-size: 20px; }
  #areaService section p {
    text-align: left; } }

@media screen and (max-width: 700px) {
  #topHeader {
    width: 100%;
    background: none;
    text-align: center; }
  #headerTxt {
    width: 100%;
    margin: 15px auto 0;
    text-align: center; }
  #headerTxt h1 {
    margin-bottom: 5px; }
  #headerTxt h1 img {
    width: 150px; }
  #headerTxt .txtCatch {
    margin-bottom: 0;
    font-size: 20px; }
  #headerTxt .txtCatch + p {
    line-height: 1;
    margin-bottom: 8px;
    font-size: 10px; }
  #headerTxt .txtCatch + p br {
    display: none; }
  #imgCatch {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto;
    z-index: 1;
    background-image: none; }
    #imgCatch img {
      width: 310px;
      margin: 0 auto;
      display: block; }
  #topHeader .appDL {
    margin-top: -32px;
    padding: 36px 0 16px; }
  #topHeader .appDL div {
    width: auto;
    margin: 0 auto;
    padding-right: 0; }
  #topHeader .appDL p {
    font-size: 12px; }
  #topHeader .appDL li {
    width: 42%;
    margin: 8px; }
  #topHeader .appDL img {
    width: 100%; }
  /** Carousel
	****************************************/
  #areaStep {
    overflow: hidden;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center; }
  #carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto; }
  .slick-slide {
    float: left; }
  .sectionStep {
    overflow: hidden;
    height: inherit;
    margin: 0 auto;
    background-size: auto 190px !important;
    text-align: left; }
  .stepImg {
    width: 38%;
    margin: 0 8px 0 0; }
  .sectionStep h2 {
    margin-top: 6px;
    margin-bottom: 10px;
    color: #6d4836;
    font-size: 20px;
    font-weight: normal; }
  .sectionStep h2:before {
    content: "";
    display: block;
    width: 105px;
    height: 33px;
    margin-bottom: 10px;
    background: url(/common/pc/img/step_img_step.png) no-repeat;
    background-size: 105px auto; }
  .sectionStep p {
    margin-bottom: 1em;
    padding-right: 8px;
    font-size: 12px; }
  .sectionStep p + p {
    display: none; }
  #step2 h2:before {
    background-position: 0 -33px; }
  #step3 h2:before {
    background-position: 0 -66px; }
  .slick-prev, .slick-next {
    display: none !important; }
  .slick-dots {
    clear: both;
    display: block;
    margin: 0;
    padding: 18px 0 0; }
  .slick-dots li {
    display: inline-block;
    margin: 0 9px; }
  .slick-dots li:hover {
    opacity: 0.7; }
  .slick-dots li button {
    width: 30px;
    height: 30px;
    line-height: 26px;
    padding: 0;
    border: 2px solid #6d4836;
    border-radius: 50px;
    background: #fff;
    text-align: center;
    color: #6d4836;
    font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Osaka,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
    font-size: 18px;
    cursor: pointer; }
  .slick-dots li.slick-active button {
    background: #6d4836;
    color: #fff; }
  /** Campaign
	****************************************/
  #areaCampaign {
    padding-top: 20px; }
  #areaCampaign img {
    width: 94%; }
  /** Service
	****************************************/
  #areaService {
    padding: 30px 0;
    text-align: center; }
  #areaService h2 {
    line-height: 1.4;
    margin-bottom: 25px;
    color: #6d4836;
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 0.12em; }
  #areaService section {
    display: inline-block;
    width: 90%;
    margin: 0 0 25px;
    text-align: left; }
  #areaService section img {
    float: left;
    width: 40%;
    margin: 0 4% 0 0; }
  #areaService section h3 {
    margin: 20px auto 12px;
    font-size: 18px; }
  #areaService section p {
    font-size: 12px;
    text-align: left; } }

